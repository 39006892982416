/**
 * list
 * 为了【列表】功能而做的公用函数，方便mixin组合进入具体的【列表型views】
 * @author mabo
 */
export default {
    data() {
        return {
            isLoading: false,
            selectionData: [], //勾选的数据
        }
    },
    methods: {
        /**
         * todo
         */
        onExport() {},
        onSelectionChange(i) {
            this.selectionData = i.map(d => d[this.getPrimarykey]);
        },
        onUnbind(row) {
            let self =this;
            self.$confirm("此操作将解除绑定, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    self.unbindAPI({
                        [self.getPrimarykey]: row[self.getPrimarykey]
                    }).then(res => {
                        self.onRefresh();
                        self.$message({
                            type: "success",
                            message: "解除绑定成功!"
                        });
                    });
                })
                .catch(() => {});
        },
        async onDelete(event) {
            let self = this;
            let text = event.target.textContent || "操作";
            if (self.selectionData.length === 0) {
                self.$message.warning("请选中要" + text + "的项！");
                return;
            } else {
                self.$msgbox
                    .confirm("确定要" + text + "选中的项吗？", "提示", {
                        type: "warning"
                    })
                    .then(async function () {
                        self.isLoading = true;
                        try {
                            await self.deleteAPI({
                                idList: self.selectionData
                            });
                            self.$notify({
                                title: "成功",
                                message: text + "成功",
                                type: "success"
                            });
                            self.$nextTick(function () {
                                self.$search(this.table);
                            });
                        } catch (e) {
                            // console.error(e);
                        }
                    }).catch((e) => {});
                self.isLoading = false;
            }
        },
        onEdit(row) {
            let self = this;
            self.$router.push({
                path: "./edit",
                query: {
                    id: row[self.getPrimarykey],
                    from: self.getFrom
                }
            });
        },
        onAdd() {
            let self = this;
            self.$router.push({
                path: "./add",
                query: {
                    from: self.getFrom
                }
            });
        },
        onDetail(row) {
            let self = this;
            self.$router.push({
                path: "./info",
                query: {
                    id: row[self.getPrimarykey]
                }
            });
        },
        //关闭当前页面
        onClose() {
            // for 标签页
            BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
        },
        async onRefresh() {
            await this.$search(this.table);
        },
    },

    computed: {
        visitedViews() {
            return this.$store.state.tagsView.visitedViews;
        },
        routes() {
            return this.$store.state.permission.routes;
        },
        getPrimarykey() {
            return this.primarykey || "id";
        },
        /**
         * 根据URL获取当前组件的名字
         */
        getName() {
            let hash = document.location.hash;
            let i = hash.lastIndexOf("/");
            if (i != -1) {
                hash = hash.substring(i + 1);
            }
            return /*this.$options.name ||*/ hash
        },
        /**
         * 根据URL获取父组件的名字
         */
        getParentName() {
            let hash = document.location.hash;
            let i = hash.lastIndexOf("/");
            if (i != -1) {
                let arr = hash.split('/');
                hash = arr[arr.length - 2] || hash;
            }
            return /*this.$options.name ||*/ hash
        },
        getFrom() {
            return this.getParentName + "/" + this.getName;
        }
    },

    async mounted() {
        let self = this;
        BUS.$on(BUSEVENT.makeEvent("refresh", self.getParentName + "/" + self.getName), () => {
            self.onRefresh();
        });
    },

    beforeDestroy() {
        BUS.$off(BUSEVENT.makeEvent("refresh", self.getParentName + "/" + self.getName));
    }
}