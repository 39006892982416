<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="onSelectionChange">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button plain style="float:right" @click="onDelete">删除</el-button>
            <!-- <el-button plain style="float:right" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/mixins/list";
export default {
  mixins: [list],

  data() {
    const vm = this;
    return {
      nav: [{ name: "国土地块管理" }],
      //【钩子字段】
      primarykey:"villageId",//主键：为列表数据的唯一性标识字段名，若不指定，默认取"id"。主要用来实现多选/单选操作
      //【钩子字段】
      deleteAPI:vm.$api_hw.plotManagement_delete,//删除/作废等批量操作的API函数名
      common_getBidNameAndIdList: [1, 0, null],//下拉框for ayl-table
      table: {
        api: vm.$api_hw.plotManagement_listPlot,
        query: {
          bidId: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "国土地块名称"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "国土地块名称",
            key: "villageName",
            showTooltip: true,
            width: "250px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onDetail.bind(this, ctx.row)
                    }
                  },
                  ctx.row.villageName || "- -"
                )
              ]);
            }
          },
          {
            title: "所属标段",
            key: "bidName",
            width: "140px"
          },
          {
            title: "养护企业",
            key: "bidMaintainUnit"
          },
          {
            title: "是否绘制",
            render(h, ctx) {
              return h("span", [
                h("span", ctx.row.isDrawn ===1 ? "是":"否")
              ]);
            }
          },
          {
            title: "人口数量",
            key: "population",
            width: "80px"
          },
          {
            title: "清扫保洁面积(m²)",
            key: "cleanArea",
            width: "200px"
          },
          {
            title: "范围",
            key: "villageScope",
            width: "200px"
          },
          {
            title: "地址",
            key: "villageAddress",
            width: "200px"
          },
          {
            title: "操作",
            width: "100px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  }    
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
